.none {
  display: none;
}

.pro-sidebar {
  display: none;
}
.sidebarHeader {
  height: 144.825px;
}
.status{
  font-size: 15px;
  align-self: center;
}
.divSvg{
  display: inline-flex;
}
.divSvg:hover{
  cursor: pointer;
  background-color: #f5eeee;
  filter: blur(0.5px);
}
.divFatherSvg:hover{
  cursor: pointer;
  background-color: #f5eeee;
  filter: blur(0.5px);
}
.svg{
  width: 50px;
}

/* desktop */
@media screen and (min-width: 768px) {

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    color: rgb(150, 150, 150);
  }

  .pro-sidebar .pro-menu .pro-menu-item-black > .pro-inner-item{
    color: #2D2D2D;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: rgb(150, 150, 150);
  }

  .pro-sidebar .pro-menu .pro-menu-item-black > .pro-inner-item:hover{
    color: #2D2D2D;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus{
    color: rgb(150, 150, 150);
  }

  .pro-sidebar .pro-menu .pro-menu-item-black > .pro-inner-item:focus{
    color: #2D2D2D;
  }

  .sidebarHeader {
    height: 80px;
  }

  .pro-sidebar .pro-sidebar-inner
  {
    background-color: white;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  }

  .pro-sidebar .pro-menu .pro-menu-item:hover {
    background-color: #f0f0f0;
  }

    .pro-sidebar.collapsed {
      width: 50px;
      min-width: 50px;
    }

    .pro-sidebar {
      display: block;
    }

   .pro-sidebar:hover {
    background-color: blue;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    font-size: 20px;
    margin-left: -15px;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border-top: unset;
    align-items: baseline;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
    font-size: 20px;
  }
}
