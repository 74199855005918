.containerFolder {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

.boxStyle {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 200px
}

.composeStyle {
    max-width: 200px;
    white-space: nowrap;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.positionRelative {
    position: relative
}

.whiteSpaceNowrap {
    white-space: nowrap
}

.textIndent20px {
    text-indent: 20px
}

.editIconStyle {
    top: 0;
    right: 0;
    position: absolute;
}

.editLayoutStyle {
    top: 0;
    right: 0;
}