.nameTitle{
    width: 33%;
}
.nameList{
    width: 33%;
}
.ListDocumetTypes{
    display: flex;
}
.itemContentDocument {
    position: relative;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid rgba(234, 234, 234, 1);
    border-right: 1px solid rgba(234, 234, 234, 1);
    border-left: 1px solid rgba(234, 234, 234, 1);  
    text-decoration: none;
    align-items: center;
    padding-left: 10px;
    height: 35px;
}

.Pendencie{
    margin-bottom: 80px;
}
.circulo{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    margin-top: 10px;
}
.circulo2{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
    margin-top: 10px;
}
.nameWork{
    display: inline-flex;
    margin-left: 40px;
}
.margin{
    width: 6px;
}
.fatherList{
    border: 1px solid rgba(234, 234, 234, 1);
    height: auto;
}


.ulContentPendecieList{
    padding: 0px;
}
.invisibleDiv{
    display: inline;
}
.invisibleDiv{
    display: none;
}
.iconbutton{
    margin-top: 1px;
}
.fatherListPendenciaVazia{
    border: 1px solid rgba(234, 234, 234, 1);
    height: 50px;
}
