/* side bar styles */

.sideBarStyles {
    height: 750px;
    border-left: 4px solid #e9e9e9; 
    border-bottom: none;
}

.sideBarStylesNotebook {
    height: 594px;
    border-left: 4px solid #e9e9e9; 
    border-bottom: none;
}

@media (min-width: 2560px) {
    .sideBarStyles {
        height: 880px;
    }
}

.sideBarStylesFull {
    height: calc(100vh - 58px);
    border-left: 4px solid #e9e9e9; 
    border-bottom: none;
}

@media screen and (min-width: 768px) {
    .sideBarStyles {
        width: 550px;
    }
}

.sideBarHeaderStyles {
    border-bottom: none;
}

.sideBarContent {
    justify-content: start;
    display: flex;
    align-items: baseline;
    /* padding-left: 25px; */
}
