.hOne {
  display:inline-block;
  color: #fff;
  text-decoration: none;
   width: max-content;
}

.hOne:hover {
  display:inline-block;
  width: max-content;
  color: #fff;
  text-decoration: none;
}

.UserApi {
  font-size: 0.8rem;
  white-space: nowrap;
}

/* mobile first */
.navbar__container {
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #f8f8f8;
  color: #114d88;
  box-shadow: 0px 0px 6px #00000029;
}

.iconMobile{
  color: #114d88;
}

.iconMobileActived{
  color: #f8f8f8;
}

.navbar__links {
  list-style-type: none;
}

.navbar__links a {
  text-decoration: none;
}

h1 {
  margin-left: 44px;
}

.navbar__links li {
  display: none;
}

.navbar__menu_btn {
  float: right;
  cursor: pointer;
  z-index: 101;
  font-size: 2rem;
  border: 3px;
  outline: 0;
  border-radius: 5px;
  padding: 5px;
}

.navbar__menu_btn_open {
  background-color: #114d88;
  color: #00000080;
}

.navbar__menu_btn_close {
  color: #114d88;
  background: transparent;

}

.navbar_menu_btn_actived {
  width: fit-content;
  cursor: pointer;
  z-index: 101;
  display: grid;
  font-size: 2rem;
  border: 0;
  background: transparent;
  outline: 0;
  border-radius: 5px;
  padding: 5px;
  top: 20px;
}

.navbar__btn_active {
  display: grid;
}

.navbar {
  z-index: 999;
  width: 100%;
}

.bread {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  grid-area: header;
}

.BtnBar{
  display: inline-flex;
  max-width: 100%;
}

.SearchBoxNavbar{
  display: none;
  position: 'relative'
}

.SearchBoxNavbarMobile{
  margin-left: 5%;
  margin-right: 5%;
  display: inline-flex;
  width: 90%;
  position: relative;
}

.justifyEnd{
  justify-self: end;
}

.none{
  display: none;
}

.showMobile{
  display: block;
}

.totalMenu{
  display: none;
}

.navbar_containerOrganizationPage{
  display: grid;
  grid-template-columns:  8fr 4fr;
  padding: 0 15px;
  width: 100%;
  grid-area: header;
  align-items: center;
  height: 5rem;
  background-color: #f8f8f8;
  color: #114d88;
}

.fatherPathList{ 
  display: inline;
}

@media screen and (min-width: 1024px) {
  
  .totalMenu{
    width: 30%;
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .showMobile{
    display: none;
  }

  .breakpoints{
    display: none;
  }

  .UserApi {
    display: none;
  }

  .navbar__menu_btn {
    display: none;
  }

  .BtnBar{
    display: inline-flex;
    width: 30%;
    align-items: baseline;
    color: #9c9c9c;
    background-color: #f8f8f8;
  }
  .PathListItem {
    text-decoration: none;
    transition: all .3s;
    cursor: pointer;
    padding: 2px;
    align-self: center;
  }
  .buttonIcon{
      background-color: #f8f8f8;
  }
  .PathListItem:hover {
      color: #818181;
  } 

  .PathListItemSlash {
      color: black;
      background-color: transparent;
  }

  .navbar__container {
    position: fixed;
    display: grid;
    grid-template-columns: 10fr 4fr 1fr;
    z-index: 999;
    grid-template-columns: 100%;
    padding: 5px;
    height: 5rem;
    align-items: center;
    grid-template-areas:
    'header';    
    background-color: #f8f8f8;
    color: #114d88;
  }

  .SearchBoxNavbar{
    margin-left: 2%;
    margin-right: 2%;
    display: inline-flex;
    width: 36%;
    position: relative;
  }

  .SearchBoxNavbarMobile{
    display: none;
  }

  .navbar__links {
    list-style-type: none;
  }

  .navbar__links a {
    text-decoration: none;
  }

  h1 {
    margin-left: 44px;
  }

  .navbar__links li {
    display: block;
  }

  .navbar_containerOrganizationPage{
    position: fixed;
    z-index: 999;
    display: grid;
    grid-template-columns: 9fr 3fr;
    padding: 5px;
    align-items: center;
    grid-template-areas:
    'header';
    width: 100%;
    background-color: #f8f8f8;
    color: #114d88;
  }
}

.inputSearch{
  height: 40px;
  width: 100%;
  padding: 0px 202px 0px 8px;
  text-indent: 20px;
  border-radius: 6px;
  border: 1px solid #989898;
}

.inputSearch:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.elementSearch{
  display: flex;
  /* width: 100%; */
  position: absolute;
  /* left: 52%; */
  right: 10px;
  top: 12px;
}

.lineVertical{
  border-left: 1px solid #bbbbbb;
  height: 18px;
  padding-left: 10px;
}

.lineVerticalRight{
  border-left: 1px solid #bbbbbb;
  height: 18px;
  margin-left: 10px;
  padding-left: 8px;
}
.searchTag{
  width: 476px;
  height: 169px;
  margin: auto;
  border: solid 1px;
}

.DivTag{
  margin: auto;
  width: 100%;
  border: 1px solid rgb(118, 118, 118);
  position: absolute;
  background-color: white;
  top: 45px;
  border-radius: 6px;
}

.listTag{
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 4px;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #dcdada;
  color: black;
  font-size: 15px
}

.listTag:hover{
  background-color: rgb(214, 214, 214);
}
