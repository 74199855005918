
@keyframes slide__down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}

.slide__down {
	animation: slide__down 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.positionClass {
  position: relative !important;
}

.labelPanel {
  text-align: left;
  font: normal normal 600 16px/24px Segoe UI;
  letter-spacing: 0px;
  color: #2D2D2D;
  opacity: 1;
  margin-bottom: 21px !important;
}

.datePicker {
  border-radius: 8;
  height: 44;
  text-align: "left";
  padding-bottom: 5;
  width: "100%";
}

.sendButton {
  width: 480px;
  height: 54px;
  bottom: 0;

  background: #005A9E 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
  border: none;
}

.sendButtonText {
  text-align: left;
  font: normal normal 600 20px/20px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}