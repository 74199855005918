.firstDiv{
  width: 33%;
  float: left;
}

.secondDiv{
  width: 33%;
  float: left;
}

.thirdDiv{
  width: 33%;
  float: left;
}

.fuorthDiv{
  width: 40px;
}

.content{
  margin: 5px;
  margin-top: 15px;
}

.content ul{
  padding: 0px;
}

.itemContentDocument.title {
  border-top: 1px solid rgba(234, 234, 234, 1);
  background-color: #f8f8f8;
}

.itemContentDocument {
  position: relative;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  border-right: 1px solid rgba(234, 234, 234, 1);
  border-left: 1px solid rgba(234, 234, 234, 1);  
  text-decoration: none;
  align-items: center;
  height: 35px;
  padding: 5px;
}
