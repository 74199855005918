.container{
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.btnAlignSelf{
    text-align: right; 
    align-self: flex-end;
}

.containerContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 260px;
    padding: 16px;
}

.containerLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 260px;
}