.painelTitle{
    margin-bottom: 8px;
    font-family: Microsoft YaHei, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
}

.hundredHeight{
    height: 100%;
}

.main{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 8px;
}

.displayFlex {
    display: flex;
}

.labelDisable{
    font-size: 14px;
    font-weight: 600;
    color: rgb(161, 159, 157);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
    margin-bottom: 8px;
    font-family: Microsoft YaHei, sans-serif;
    -webkit-font-smoothing: antialiased;
}