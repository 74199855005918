@keyframes wizard__anim__enter {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}


.wizard__anim__enter {
	animation: wizard__anim__enter 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}