.nameTitle{
    width: 33%;
}
.nameList{
    width: 33%;
}
.ListDocumetTypes{
    display: flex;
}
.itemContentDocument {
    position: relative;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid rgba(234, 234, 234, 1);
    border-right: 1px solid rgba(234, 234, 234, 1);
    border-left: 1px solid rgba(234, 234, 234, 1);  
    text-decoration: none;
    align-items: center;
    padding-left: 10px;
    height: 35px;
  }