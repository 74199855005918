.main {
  margin: 20px;
}

.error {
  margin: auto;
  width: 350px;
  padding: 10px;
}

.main h3 {
  margin: 5px;
  text-align: center;
}

.backOperationText{
  float: right;

}
.fowarOperationText{
  float: right;
  background-color: #0078d4;
  border:none;
  color:white
}
.fowarOperationText:hover {  
 border:none;
 background-color:#0078d4;
 color:white;
}

.iconHome{
  cursor: pointer;
}
