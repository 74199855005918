.PdfPage {
    position: relative;
}
.pageState{
    position: relative;
    display: inline-block;
}
.PdfPage__textLayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    line-height: 1;
}
.PdfPage__textLayer > span {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
}
.PdfPage__textLayer > div {
     color: transparent;
     position: absolute;
     white-space: pre;
     cursor: text;
     transform-origin: 0% 0%;
}
.sidebarMain {
    display: flex;
    position: sticky;
    top: 0;
    min-width: 154px;
    background-color: #FBFBFB;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;
    overflow-y: scroll;
    
}
.sidebarMain::-webkit-scrollbar{
    display: none;
}
.sidebarPage{
    max-width: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sidebarCanvas{
    max-width: 104px;
    max-height: 128px;
    border: 6px solid #E2E2E2;
    border-radius: 4px;
    cursor: pointer;
}
.btnClose{
    padding-top:8px;
    width: 2%;
}
.iconClose{
    font-size:26px;
    cursor:pointer;
    position:absolute;
    right: 10px;
}
.header{
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    align-items: center;
    height: 46px;
    background-color: #FBFBFB;
}
.mainHeader{
    display: flex;
    align-items: center;
    width: 516px;
    height: 32px;
    justify-content: center;
}
.buttonIconHeader{
    height: 32px;
    width: 32px;  
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
}
.buttonIconHeader:hover{
    border-radius: 16px;
    background-color: #EEEEEE;
}
.buttonIconPrint{
    height: 32px;
    width: 32px;  
    background: none;
    border: none;
    cursor: pointer;
}
.buttonIconPrint:hover{
    border-radius: 16px;
    background-color: #EEEEEE;
}
.buttonIconActive{
    border-radius: 16px;
    background-color: #EEEEEE;
}
.displayFlex{
    display: flex;
    align-items: center;
}
.title{
    font: normal normal 600 16px/24px Segoe UI;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding-left: 8px;
}
.iconSize{
    height: 16px;
    width: 16px;
}
.closeButton{
    justify-self: self-end;
}
.gapSpan{
    gap: 4px;
}
.spanPage{
    padding: 4px;
    background-color: #F9F9F9;
    border: 1px solid #C2C2C2;
}
.search{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 436px;
    height: 55px;
    border-radius: 10px;
    z-index: 1;
    position: fixed;
    background-color: #FFFFFF;
    border: 1px solid #C2C2C2;
}
.inputSearch{
    width: 240px;
    height: 35px;
}
.pagePreview{
    height: 83px;
    width: 64px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 2px 2px #00000029;
}
.padding{
    padding: 12px 8px;
}



