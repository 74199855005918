.fatherList{
    display: inline-flex;
    width: 100%;
    height: 100%;
    font-family:"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
}


.NameItemList{
    width: calc(20% - 49px );
    padding-left: 15px;
    padding-right: 8px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Name{
    width: calc(20% - 49px );
    padding-left: 15px;
    padding-right: 8px;
}

.icons{
    width: 26px !important;
}


.Action{
    width: 20%;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.RevisionList{
    width: 16%;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1%;
}

.NameUserList{
    width: 16%;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1%;

}

.IpList{
    width: 18%;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Revision{
    width: 16%;
    padding-left: 1%;
}
.User{
    width: 16%;
    padding-left: 1%;
}
.Ip{
    width: 18%;
}
.stackFather{
    width: 21%;
}
.stackSide{
    display: flex;
    height: 44px;
}
.DateBegin{
    width: 106%;
    margin-right: 6%;
}
.DateEnd{
    width: 8%;
}

.textDateBegin{
    margin: auto;
    padding-right: 2%;
}
.textDateEnd{
    margin: auto;
    padding-right: 2%;
}

.dateLabelContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
}

.labelDateBegin{
    width: 50%;
    margin: auto;
    padding-right: 2%;
}
.labelDateEnd{
    width: 50%;
    margin: auto;
    padding-right: 2%;
}

.calendarBtnContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    /* justify-content: center; */
}

.calendarBtnStart{
    display: flex;
    width: 50%;
    margin: auto;
    padding-right: 2%;
    justify-content: center;
}
.calendarBtnEnd{
    display: flex;
    width: 50%;
    margin: auto;
    padding-right: 2%;
    justify-content: center;
}

.dateLabelContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.rangePickerInput{
    margin-bottom: 8px;
}