* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.test {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    width: 95%;
    height: 100%;
    z-index: 1;
    background-color: transparent;
}

/* Dialog Container */

.dialogContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.dialogCenter {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 3px;
}

.nameCenter {
    display: flex;
    width: 100%;
    height: 100%;
}

.testBetween {
    display: flex;
    background-color: blue;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.Title {
    position: absolute;
    left: 0;
    top: 0;
}


/* responsability styles */

.responsabilityNameContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    flex-direction: column;

    width: 150px;
    height: 150px;
    padding: 10px;

    border-radius: 50%;
    border: 5px solid black;
}

.responsabilityNameContent {
    color: black;
    width: 100%;
    padding: 5px;
    font-size: 18px;
    font-weight: bold;
}

/* array styles */

.arrowSizes {
    width: 100px;
}

.spaceRight {
    padding-right: 24px;
}

.nameCardContainer {
    display: flex;
    max-width: 300px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.nameCardContent {
    font-size: 12px;
}

nerFather {
    display: flex; 
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.ContainerMain {
    display: flex;
    padding: 20px;
}

.ActivityName {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CentralizeText {
    height: 150px;
    display: flex;
    flex-direction: column;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
}

.BoxContextSize {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}


