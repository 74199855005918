/* my styles */

.mainHeader {
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.headerName {
  width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
}

.folderName {
  font-size: 20px;
}

.headerButtonsContainer {
  display: flex;
  align-items: center;
}

.HeaderButton {
  width: 100%;
}

@media screen and (max-width: 990px) {
  .mainHeader {
    flex-direction: column;
  }
  .headerButtonsContainer {
    margin-top: 10px;
  }
}

@media screen and (max-width: 690px) {
  .headerButtonsContainer {
    display: flex;
    flex-direction: column;
  }
  .folderName {
    font-size: 24px;
    width: 100%;
    padding: 5px;
  }
  .headerName {
    width: 100%;
    text-align: center;
  }
}

/* @media screen and (max-width: 1130px) {
  .test2 {
    flex-direction: column;
  }
  .nameFolderFont {
    font-size: 26px;
  }
  .test2 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 10px;
  }
}

@media screen and (max-width: 810px) {
  .btnFileHeader {
    width: 100%;
  }
} */



.ulContent {
  margin: 0px;
  padding: 0px;
}

.noRegistry {
  flex: 1;
  border-left: 1px solid rgba(45, 45, 45, 0.2);
  border-bottom: 1px solid rgba(45, 45, 45, 0.2);
  border-right: 1px solid rgba(45, 45, 45, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.hList {
  color: black;
}

.itemContentAdm {
  position: relative;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  border-right: 1px solid rgba(234, 234, 234, 1);
  border-left: 1px solid rgba(234, 234, 234, 1);
  text-decoration: none;
  min-height: 40px;
  padding: 8px;
}
.itemContent {
  position: relative;
  box-sizing: border-box;
  display: flex;
  border-top: 1px solid rgba(234, 234, 234, 1);
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  border-right: 1px solid rgba(234, 234, 234, 1);
  border-left: 1px solid rgba(234, 234, 234, 1);  
  text-decoration: none;
  align-items: center;
  padding-left: 10px;
  min-height: 40px;
  padding: 8px;

}

li.itemContent:focus {
  font-weight: bold;
  background-color: rgb(240, 238, 238);
}


.lessMargin { 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
  margin-bottom: -8px;
}

.marginMenu{
  margin-bottom: -8px;
  margin-top: 7px;
}

.itemContent.row:hover {
  background-color: rgb(240, 238, 238);
}

.itemContent.rowOrg:hover {
  background-color: rgb(240, 238, 238);
}

.itemContent.rowOrg {
  cursor: pointer;
}

.itemContent.title{
  border-top: 1px solid rgba(234, 234, 234, 1);
  font-weight: bold;
}

.itemBox {
  padding: 0.5625rem 0.75rem;
  color: black;
}

.itemBox:hover {
  text-decoration: none;
  color: black;
}

.BtnNew{
  text-align: right;
}

.BtnAdd{
  display: flex;
  flex-direction: row;
}

.btnFlex{
  display: flex;
  justify-content: flex-end;
}

.hPage{
  margin-bottom: 25px;
  margin-top: 8px;
}

.partName {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.stackSide{
  width: 381px;
  text-align: right;
}

.stackSideBtnBar{
  display: flex;
}

.InputSearch{
  text-align: -webkit-right;
}
.top{
  margin-top: 50px;
}

.iconButton{
  position: sticky;
  left: 10000px;
}

.nameField{
  width: 100%;
  max-width: 300px;
}

.pointer{
  cursor: pointer;
}

.commandButton
{
  background-color: #114D88;
  color: white;
  border-color: #114D88;
  margin:5px;
  font-size: 14px;
  font-weight: 600;
}

.commandButton i{
  color: white;
}

.commandButton:hover
{
  background-color: white;
  color: #114D88;
  border-color: #114D88;
  margin:5px;
}

.commandButton:hover i{
  color: #114D88;
}
.cursorList{
  cursor: pointer;
}
.itemContentAdm:hover {
  background-color: rgb(240, 238, 238);
}

.disabledList{
  pointer-events:none; 
  opacity:0.6;   
}

.disabledTrue{
  pointer-events:auto; 
  opacity:2;   
}

.btnSeleciont{
  margin-left: auto;
  height: fit-content;
}

.DivFatherListUser{
  display: block;
}

.DivListNameUser{
  display: flex;
}

.menuCount {
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 1024px) {
  .lessMargin {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.titleTemporality{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.titleLabelTemporality{
  margin: 5px
}

.inputTemporality{
  margin: -5px
}

.hlistTemporality{
  color: black;
  padding-top: 13px
}

.iconPrazo{
  position: sticky;
  left: 10000px;
  margin-top: -8px;
  margin-bottom: -8px;

  border: none;
  cursor: pointer;
  padding: 0px 4px;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  user-select: none
}


.iconPrazoTemporalityList{
  position: sticky;
  left: 10000px;
  margin-bottom: -8px;

  border: none;
  padding: 0px 4px;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  user-select: none
}