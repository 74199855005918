.focus:focus {
    border-radius: 8px;
    outline: none;
}

.scroll__container {
overflow-x: scroll;
width: 100%;
}
  
.scroll__content {
display: inline-block;
white-space: nowrap;
}

.iconBehavior {
    position: absolute;
    top: 0;
    right: 0;
}

.fontStyles {
    text-align: left;
    font: normal normal 600 18px/27px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 1;
}

/* first column styles */
.firstChildColumn {
    width: 60%;
    justify-content: space-between;
}
.firstItemsColumn {
    /* display: flex; */
    justify-content: space-between;
}
.fistItemsStylesColumn {
    display: flex;
    width: 100%;
    gap: 20px;
    position: relative
}
.firstItemsEllipses {
    /* display: flex; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 50px);
}

.borderGridbox {
    border: 1px solid #B5B5B5;
    border-radius: 10px;
    margin-bottom: 16px
}

.boxLayoutGrid {
    grid-template-columns: 1fr;
    grid-template-rows: 75px 75px 75px;
    padding: 16px;
}

.firstRowStyle {
    width: 100%;
    display: flex;
    position: relative;
    padding-bottom: 3px;
    border-bottom:  1px solid #B5B5B5;
    margin-bottom: 30px;
}

.firstRowStyleAlter {
    width: 100%;
    display: flex;
    position: relative;
    height: 45px;
}

.firstRowStyleAlter::after {
    content: '';
    display: inline-block;
    height: 1px;
    width: 100%;
    background: #B5B5B5;
    position: absolute;
    bottom: 10px;
}
.firstRowSecondPartRowStyle {
    width: 40%;
    display: flex;
    position: relative;
    top: 0px
}
.firstRowSecondPartChildColumn {
    display: flex;
    justify-content: end;
    width: 100%;
}
.firstRowSecondPartItemsDiv {
    display: flex;
    gap: 10px;
    justify-content: end;
    /* width: 100%; */
    align-items: flex-start;
}
.deadLineStyles {
    margin-top: 3px;
}
.statusTagAdjust {
    width: 100px;
}
/* ---------------------- */

/* second column styles */
.secondRowStyles .title {
    text-align: left;
    font: normal normal 600 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 0.6;
}
.secondRowStyles {
    padding-bottom: 3px;
    margin-bottom: 30px;
}
/* ---------------------- */

/* third row styles  */
.thirdRowStyles {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 3px;
}
/* ---------------------- */



.divider {
    border-bottom: '1px solid black';
}

.ellipses {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.emptySlot {
    opacity: 0.2;
    font-size: 16px;
}

.newName {
    display: flex;
}

.nameGrid {
    text-indent: 15px;
    position: relative;

    overflow: hidden;
    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.nameGridBoxWithTextField {
    display: flex;
    text-indent: 15px;
    position: relative;
    cursor: pointer;
}

.nameGridDetail {
    text-align: left;
    font: normal normal bold 34px/42px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 1;
    position: relative;
    display: flex;
    white-space: nowrap;
}

.nameGridDetailWithTextField {
    text-align: left;
    font: normal normal bold 34px/42px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 1;
    position: relative;
    display: flex;
    width: 400px;
}

.nameTitle{
    text-indent: 0px;
}

.descriptionWrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gridBorder {
    border: 1px solid #b5b5b5;
    border-radius: 8px;
}

.load {
    position: absolute;
    top: 0;
    right: 0;
}


.gridTitleElement {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.6;
    display: flex;
}

.scroll__folder {
    width: fit-content;
    overflow-x: hidden;
}

.checkboxContainer {
    width: 18px;
    height: 18px;
    cursor: pointer;
}


.checkboxTitleContainer {
    position: relative;
    right: 2px;
}
.ulContent {
    margin: 0px;
  }
.ulContentPendecies{
    margin: 0px;
    padding: 0px;
}
.ulContentComp{
    padding: 0px;
}
.folder{
    cursor: pointer;
    overflow: overlay;
    position: relative;
    background-color: #FCFCFC;
    box-shadow: 4px 0px 0.6px #00000014;
    opacity: 1;
    padding-top: 23px;
}
.scroll{
    overflow-x: scroll;
}

.background {
    background-color: #FBFBFB;
}

.files{
    display: flex;
    flex-grow:1;
    flex-direction:column;
    padding: 30px;
    overflow-y: auto;
}
.descriptionTitle{
    width: 20%;
    position: relative;
    right: 8px;
}
.divFatherDesc{
    display: contents;
}
.TextFieldDesc{
    width: 20%;
    display: inline-flex;
}
.iconsGrid{
    display: flex;
}
.TextFieldName{
    width: 17%;
    display: inline-flex;
    padding-left: 15px;
    padding-right: 8px;
}
.Editicon{
    cursor: pointer;
    float: right;
}
.EditiconName{
    cursor: pointer;
    float: right;
}
.checkicon{
    cursor: pointer;
}
.closeicon{
    cursor: pointer;
}
.father{
    display: inline-flex;
    width: 100%;
    height: 100%;
}
.fatherFile{
    display: inline-flex;
    width: 100%;
    height: 100%;
    font-family:"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
}
.uploadInput {
    display: none;
  }

.main{
    height: 100%;
}
.FileSize{
    width: 15.5%;
}
.ModifiedBy{
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10%;
    text-indent: 8px;
}
.Name{
    padding-left: 8px;
    padding-right: 8px;
    width:17%
}
.StatusTitle{
    width:12%;
    text-align: center;
}
.Workflow{
    width: 12.5%;
}
.NameList{
    width: 17%;
    display: inline-flex;
    padding-right: 8px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.NameList div{
    word-wrap: break-word;
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}

.mainPathList{
    display: inline;
    color: #9c9c9c;
    background-color: #f8f8f8;
    padding: 8px;
    margin-left: -8px;
    margin-bottom: 8px;
    border-radius: 8px;
}

.lastTextPathist{
    color: #000;
    cursor: pointer;
    font-weight: bold;
}

.fatherPathList{ 
    display: inline;
}
.PathListItem {
    text-decoration: none;
    transition: all .3s;
    cursor: pointer;
    padding: 2px;
}
.buttonIcon{
    background-color: #f8f8f8;
    align-self: center;
}
.PathListItem:hover {
    color: #818181;
} 

.PathListItemSlash {
    color: black;
    background-color: transparent;
}

.nameExtensionList{
    width: 100%;
    cursor: pointer !important;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.TagList{
    display: flex;
}

.defaultDateList{
    position: sticky;
    margin-bottom: -8px;
}
        
.defaultDateList svg{
    color: black;
}

.SizeList{
    width: 8.5%;
}
.StatusList{
    width: 12%;
    text-align: center
}
.fifile{
    width: 26px;
}
.header{
    margin: 53px 0px 30px 0px;
    border-bottom: 1px solid #e7e7e7;
}
.headerTitle{
    padding-top: 8px;
}

.hoverDrop{
    border: solid 3px;
    border-radius: 5px;
    border-color: #0078d4;
}

.NameItemRevision{
    width: 55%;
}
.itemNameList{
    width: 57%;
    cursor: pointer;
    word-wrap: break-word;
}
.IconFileRevision{
    width: 16%;
}

.IconFilesRevisionList{
    width: 14%;
}

.draftDateList{
    width: calc(29% - 24px);
}

.btnWorkflow{
    height: 99%;
    width: -webkit-fill-available;
    margin-block-start: auto;
}

.btnWorkflowLoader{
    height: 99%;
    width: -webkit-fill-available;
    margin-block-start: auto;
    display: flex;
    justify-content: center;
}

.validation{
    margin-left: -37px;
}
.divWorkflow{
    display: contents;
}
.DivActionFlow{
    width: 11%;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}
.layout{
    width: 13%;
}
.fileSizePendencie{
    width: 12%;
    text-align: center;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.desc{
    width: 20%;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}
.ValidationRule{
    width: 11%;
    text-align: center;
    /* padding-right: 30px; */
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.DateModifield{
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    width: 9.5%
}
.stickyTitle{
    position: sticky;
    top: -1px;
    background-color: #fff;
    z-index: 1;
}

@media screen and (max-width: 1440px) {
    .FileSize{
        width: 15%;
    }
    .DateModifield{
        width:11%;
        text-align-last: center;
    }
    .IconTitle{
        width: 3%;
    }
    .Name{
        width: calc(17% + 23px);
    }
    .ModifiedBy{
        width: 10%;
        padding-right: 1%;
    }
    .desc{
        width: 20%;
    }
    .DateList{
        width: 11%;
    }

    .defaultDateList{
        position: sticky;
        margin-bottom: -8px;
    }
            
    .defaultDateList svg{
        color: black;
    }

    .fileSizePendencie{
        width: 12%;
    }
    .StatusList{
        width: 10%;
    }
    .descriptionTitle{
        width: 19.5%;
    }
    .ValidationRule{
        width: 11.5%;
    }
    .NameList{
        width: 20%;
    }
}
@media screen and (max-width: 1200px) {
    .DivActionFlow{
        width: 12%;
    }
    .fatherFile{
        display: block;
    }
    .listNone{
        display: none;
    }
    .listMidea{
        height: 179px;
        margin: 20px;
        border: solid 1px rgba(234, 234, 234, 1);
    }
    .IconFile{
        float: left;
    }
    .fifile{
        float: left;
    }
    .NameList{
        padding-top: 3px;
    }
    .menuList{
        float: right;
    }
    .listMideaRevision{
        height: 56px;
        margin: 20px;
        border: solid 1px rgba(234, 234, 234, 1);
    }
}

.iconClose{
    float: right;
    cursor: pointer;
}

.Enumerate{
    width: 3%
}

.pointerHover:hover{
    cursor: pointer;
}

.nameItem{
    font: normal normal 600 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 0.6;
}

.descItem{
    font: normal normal 600 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 0.6;
    margin-top: 20px;
}

.dataItem{
    font: normal normal 600 18px/23px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 1;
}

.gridTags{
    display: grid;
    margin-bottom: 18px;
}

.labelTag{
    font: normal normal 600 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 0.6;
}

.lengthTag{
    font: normal normal normal 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 0.5;
    margin: auto;
}

.imgSubstituir{
    width: 16px;   
    cursor: pointer;
}

.textModalTemporality{
    width: 725px;
    height: 45px;
    /* UI Properties */
    text-align: center;
    font: normal normal normal 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 1;
    margin-bottom: 30px;
}

.buttonsModalTemporality{
    display: flex;
    place-content: center;
}

.warningIcon{
    text-align: -webkit-center
}

.hList{
    text-align: center;
    padding-bottom: 6px;
}

.waitingTagLoader{
    position: fixed;
    bottom: 68px;
    width: 465px;
}

.painelTitle{
    margin-bottom: 8px;
    font-family: Microsoft YaHei, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
}

.buttonsDiv{
    display: flex;
    gap: 8px;
    flex-direction: column;
}