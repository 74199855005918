.nameUser{
	font-size: 15px;
	color: #114d88;
}
.circleIcon{
	width: 50px !important;
	color: #114d88;
}
.iconMenu{
	float: right !important;
}
.icons{
	display: contents;
}
.iconRight
{
	margin-left: auto;
	float: right;
}
.hList{
	color: black;
}
.linhaHr{
    margin-right: 10px;
}
.tituloLinks{
	color:black;
}
.copy{
	float: right;
    margin-right: 10px;
	cursor: pointer;
}
.inputCopy{
	width: 60%;
    border: none;
}
.close{
	margin-left: 58%;
	cursor: pointer;
}

@media screen and (max-width: 768px) {

}