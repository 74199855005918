* {
  font-family: "Segoe UI";
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
   list-style-type: none;
}
/* 
.app {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.flags-container {
  display: flex;
  justify-content: right;
}

.flag {
  cursor: pointer;
  width: 30px;
  margin: 10px;
  filter: grayscale(1);
}

.flag:hover {
  filter: grayscale(0.5);
}

.selected {
  filter: grayscale(0);
}

.selected:hover {
  filter: grayscale(0);
}

.ms-Nav-compositeLink.is-selected {
  background: white;
}

.ms-Nav-compositeLink.is-selected button{
  background: transparent;
}


.ms-Nav-compositeLink.is-selected button::after {
    border-left: 0px solid white;
    content: "";
    position: absolute;
    inset: 0px;
    pointer-events: none;
}