.HomeComp {

margin: auto;
width: 90%;
}

.headerMain {
    position: fixed;
    z-index: 998;
    height: 100%;
    width: 100%;
    display:flex;
  }

.HomeComp {
    text-align: center;
}
.paddingExcencial {
    padding: 25px;
}

.FormComp {
    text-align: left;
    margin-top: 0px;
    display:flex;
    flex-grow:1;
    flex-direction:column;
    overflow-y: auto;
}

@media screen and (min-width: 768px) {    
    .FormComp {
        margin-top: 80px;
    }
}

.FormCompWithoutPadding {
    text-align: left;
    margin-top: 0px;
    display:flex;
    flex-grow:1;
    flex-direction:column;
    overflow-y: auto;
}


@media screen and (min-width: 768px) {    
    .FormCompWithoutPadding {
        margin-top: 0px;
    }
}