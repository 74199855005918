@media screen and (min-width: 1024px){
    .userBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


.userBody {
    background: #f6f6f6;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    overflow-y: visible;
    background: rgb(255,255,255);
    background: linear-gradient(to right, white 30%, transparent);
}

.infoContainer {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 45px;
    padding: 5px;
    position: relative;
}


.imgLogo{
    display: block;
    width: auto;
    margin: auto;
    max-width: 135px;
}

.logow3kContainer {
    position: relative;
    width: 70%;
    height: 70px;
    display: block;
}

.logoW3k{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
}

.hrContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    width: 50%;
    position: relative;
    max-width: 200px;
    padding: 5px;
}

.hrDivisao{   
    width: 100%;
}

.PoliticaPrivacidade{
    text-decoration: none;
    font-size: 16px;
    color: black;
}


/* background-color: #e5e5e5; */

