.headerViewer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;
    padding: 8px;
    background-color: #FBFBFB;
    position: relative;
    z-index: 1;
    box-shadow: 0px 4px 6px #00000014;
}
.headerViwerCollapsed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;
    padding: 8px;
    background-color: #FBFBFB;
    position: relative;
    z-index: 1;
    box-shadow: 0px 4px 6px #00000014;
    width: 95%;
}
.headerViwerFullScreenSetted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;
    padding: 8px;
    background-color: #FBFBFB;
    position: relative;
    z-index: 1;
    box-shadow: 0px 4px 6px #00000014;
    width: 100%;
}
.titleDivViewer{
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: flex-start;
}
.closeDivViewer{
    display: flex;
    width: 33%;
    justify-content: flex-end;
}
.displayFlexViewer{
    display: flex;
    align-items: center;
}
.buttonIconHeaderViewer{
    height: 32px;
    width: 32px;  
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
}
.buttonIconHeaderViewer:hover{
    border-radius: 16px;
    background-color: #EEEEEE;
}
.buttonMenuIconHeader{
    height: 32px;
    width: 32px;  
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
}
.iconSizeViewer{
    height: 16px;
    width: 16px;
}
.titleViewer{
    font: normal normal 600 16px/24px Segoe UI;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* width: 100%; */
    padding-left: 8px;
}
.mainHeader{
    display: flex;
    align-items: center;
    width: 516px;
    height: 32px;
    justify-content: center;
}
.mainContent{
    display: flex;
    align-items: center;
}
.closeButtonViewer{
    justify-self: self-end;
}
.pagePreview{
    height: 83px;
    width: 64px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 2px 2px #00000029;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
}
.paddingPreview{
    padding: 12px 8px;
}
.viewNextMain{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FBFBFB;
    width: 154px;
    height: 142px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: solid #00000014 4px;
}
.viewNextDiv{
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.viewNextItem{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.buttonIconViewNext{
    height: 28px;
    width: 28px;  
    padding: 6px;
    background: none;
    border: none;
    cursor: pointer;
    background-color: #005A9E;
    border-radius: 16px;
}
.sidebarViewerMain{
    background-color: #FBFBFB;
    width: 154px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 2px 0px #00000014;
}
.currentPreviewMain {
    display: flex;
    position: sticky;
    top: 0;
    min-width: 154px;
    background-color: #FBFBFB;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px 8px;
    overflow-y: scroll;
    
}
.currentPreviewMain::-webkit-scrollbar{
    display: none;
}
.flexLoadingViewer{
    display: flex;
    flex-direction: column;
    align-items: center
}
.pageCurrPrev{
    min-width: 109px;
    min-height: 163px;
    max-width: 109px;
    max-height: 163px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.imgCurrPrev{
    height: 128px;
    width: 93px;
    max-height: 128px;
    max-width: 93px;
    border: 1px solid #D1D1D1;
    outline: 8px solid #F5F5F5;
    border-radius: 4px;
}
.pdfCurrPrev{
    height: 128px;
    width: 93px;
    max-height: 128px;
    max-width: 93px;
    border: 1px solid #D1D1D1;
    outline: 8px solid #F5F5F5;
    border-radius: 4px;
    cursor: pointer;
}
.iconCurrPrev{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 93px;
    min-height: 128px;
    border: 1px solid #D1D1D1;
    outline: 8px solid #F5F5F5;
    border-radius: 4px;
}
.imgCurrPrev:hover{
    outline: 8px solid #ededed;
}
.pdfCurrPrev:hover{
    outline: 8px solid #ededed;
}
.iconCurrPrev:hover{
    outline: 8px solid #ededed;
}

.imageSizePreview{
    object-fit: cover;
    width: 100%;
    height: 100%
}
.buttonIconHeader{
    height: 32px;
    width: 32px;  
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
}
.buttonIconHeader:hover{
    border-radius: 16px;
    background-color: #EEEEEE;
}
.sidebarPage{
    /* max-width: 168px; */
    min-width: 109px;
    min-height: 163px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}