/* metodologia mobile first */
.ContainerMobile {
  background-color: rgb(17, 77, 136);
  position: fixed;
  display: block;
  color: white;
  right: 16px;
  top: 55px;
  z-index: 9999;
  border-radius: 5px;
}

.ContainerMobile a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  position: relative;
  border-radius: 5px;
}

.ContainerMobile a:hover {
  background-color: #ddd;
  color: black;
  cursor: pointer;
}

.divFatherSvgHamb{
  height: 50.4px;
  display: flex;
}

.divFatherSvgHamb:hover{
  cursor: pointer;
  color: black;
  background-color: #f5eeee;
}

.divSvgHamb{
  display: inline-flex;
  align-items: center;
}

.divSvgHamb:hover{
  cursor: pointer;
  background-color: #f5eeee;
}

.svgHamb{
  width: 32px;
}

.svgPath{
  filter: invert(100%) sepia(6%) saturate(18%) hue-rotate(188deg) brightness(105%) contrast(100%);

}

.divFatherSvgHamb:hover .svgPath{
  filter: invert(0%) sepia(0%) saturate(25%) hue-rotate(113deg) brightness(88%) contrast(108%);
}

