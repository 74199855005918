
.gridTemporalityTitleLi{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.gridTemporalityTitleLi, .gridTemporalityBodyLi {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    border: 1px solid rgba(45, 45, 45, 0.2);
    color: hsla(0, 0%, 18%, 0.6);
}
.gridTemporalityBodyLi{
    border-top: 0px;
    height: 44px;
}

.gridTemporalityTitleDiv, .gridTemporalityBodyDiv{
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(45, 45, 45, 0.2);
    white-space: nowrap;      
    overflow: hidden; 
    text-overflow: ellipsis;
}

.gridTemporalityTitleDiv {
    height: 44px;
    color: rgba(45, 45, 45, 0.6);
}

.gridTemporalityBodyDiv {
    height: 100%;
    color: rgba(45, 45, 45);
}

.gridTemporalitySpan{
    white-space: nowrap;      
    overflow: hidden; 
    text-overflow: ellipsis;
}

