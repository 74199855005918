.fileUploader {
    z-index: 999;
    position: fixed;
    bottom: 0px;
    right: 20px;
    background-color: #f8f8f8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid rgb(17, 77, 136);
    color: #114d88;
    padding: 5px;
  }

  .icon {
    color: #114d88;
    font-weight: bold;
    background-color: #f8f8f8;
  }

  .icon:hover {
    color: #114d88;
    background-color: #f8f8f8;
    font-weight: bold;
  }

  .fileUploader button:hover
  {
    background-color: #f8f8f8;
  }

  .invisible{
    display:none;
  }
  
  .visible{
    display:block;
  }