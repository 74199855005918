.react-transform-wrapper
{
    width: 100%;
    height: 100%;
}

.react-transform-component
{
    width: 100%;
    height: 100%;
}