.conclusionDiv {
    position: relative;
    height: 750px;
}

.nameTitle{
    width: 33%;
}
.itemNameTitle{
    width: 100%;
}
.nameList{
    width: 33%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.itemNameList{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}
.itemContentChoice:hover{
    cursor: pointer;
    color: #114D88;
    background-color: rgb(240, 238, 238);
}
.paddingRight{
    padding-right: 10px;
}
.ListDocumetTypes{
    display: flex;
}
.itemContentDocument {
    position: relative;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid rgba(234, 234, 234, 1);
    border-right: 1px solid rgba(234, 234, 234, 1);
    border-left: 1px solid rgba(234, 234, 234, 1);  
    text-decoration: none;
    align-items: center;
    padding-left: 10px;
    height: 35px;
  }
.btnVr{
    width: min-content;
    height: min-content;
}
.btnValidationR button{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.validation ul{
    padding: 0px;
}